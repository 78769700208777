import * as React from 'react';
import Layout from '../components/Layout';
import Container from '../components/Container';

const Contact: React.FC = () => (
  <Layout>
    <Container>Contact page content</Container>
  </Layout>
);

export default Contact;
